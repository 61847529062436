import instance from '../../lib/axios/instance';

import type { GuidancePK } from './types';

export default {
	getGuidancesPDF: async <T extends GuidancePK, R = Blob>({ storeId, id }: T): Promise<R> =>
		(await instance.get(`/pharms/${storeId}/guidances/${id}/pdf`, { responseType: 'blob' })).data,
	getGuidancesHTML: async <T extends GuidancePK, R = string>({ storeId, id }: T): Promise<R> =>
		(await instance.get(`/pharms/${storeId}/guidances/${id}/html`)).data,
};
