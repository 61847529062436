import { useMutation } from '@tanstack/react-query';

import { api } from '..';

import type { GuidancePK } from './types';
import type { ErrorData } from '../types';
import type { AxiosError } from 'axios';

export const GUIDANCE_KEYS = {
	all: ['guidance'] as const,
	getGuidancePDF: () => [...GUIDANCE_KEYS.all, 'pdf'] as const,
	getGuidanceHTML: () => [...GUIDANCE_KEYS.all, 'html'] as const,
} as const;

export const useGetGuidancePDF = () =>
	useMutation<Blob, AxiosError<ErrorData>, GuidancePK>({
		mutationKey: GUIDANCE_KEYS.getGuidancePDF(),
		mutationFn: async (params) => await api.guidances.getGuidancesPDF(params),
	});

export const useGetGuidanceHTML = () =>
	useMutation<string, AxiosError<ErrorData>, GuidancePK>({
		mutationKey: GUIDANCE_KEYS.getGuidanceHTML(),
		mutationFn: async (params) => await api.guidances.getGuidancesHTML(params),
	});
