type MessageTypes = 'BASIC' | 'AUTO' | 'FUNCTIONAL' | 'ASSEMBLY';
type BasicMessageTypes = 'TEXT' | 'FILE' | 'IMAGE' | 'ANNOUNCEMENT' | 'DELETED';

const BASE_CUSTOM_TYPES = ['FILE', 'ANNOUNCEMENT', 'DELETED'] as const;
type BaseCustomType = (typeof BASE_CUSTOM_TYPES)[number];

// > 자동응답
const AUTO_CUSTOM_TYPES = ['WELCOME', 'SUPPLEMENT', 'BUSY', 'CLOSE', 'PICKED_UP'] as const;
type AutoCustomType = (typeof AUTO_CUSTOM_TYPES)[number];

// 기능성 메시지
const FUNC_CUSTOM_TYPES = [
	'PRESCRIPTION',
	'RECEIPT',
	'SIDE_EFFECT',
	'PAYMENT',
	'PROMOTION',
	'PHARM_SALE',
	'PHARM_RECEIPT',
	'PHARM_GUIDANCE',
] as const;
type FuncCustomType = (typeof FUNC_CUSTOM_TYPES)[number];

type MessageCustomType = BaseCustomType | AutoCustomType | FuncCustomType;

export { BASE_CUSTOM_TYPES, AUTO_CUSTOM_TYPES, FUNC_CUSTOM_TYPES };
export type { MessageTypes, BaseCustomType, AutoCustomType, FuncCustomType, MessageCustomType };

interface QRData {
	data: string;
	manufactur: string;
	expiredAt: string;
}

interface FileData {
	assetId: number;
	fileName: string;
	isImage: boolean;
	url: string;
	thumbnailUrl: string;
	size: number;
	expiredAt: string;
}

interface Prescription {
	files: FileData[];
	qrCodes?: QRData[] | null;
}

interface Receipt {
	startDate: string; // yyyy-mm-dd
	endDate: string;
}

interface SideEffect {
	disease: string;
	drug: string;
	symptoms: string;
	files?: FileData[];
}

type PaymentType = 'OTC' | 'ETC' | 'PROMOTION';
type PaymentStatus = 'READY' | 'COMPLETED' | 'ZERO' | 'DIRECT';

export interface Payment {
	type: PaymentType;
	totalAmount: number;
	status: PaymentStatus;
	products?: Product[];
	orderName: string;
	orderId?: number;
	actualAmount?: number;
	coupon?: number;
	point?: number;
}

interface Product {
	name: string;
	file: FileData;

	originalPrice: number; // 정가
	actualPrice: number; // 실판매가
	quantity: number; // 수량
}

interface Promotion {
	promotionId: number;
	totalAmount: number;
	products: Product[];
}

interface FileMessageData {
	files: FileData[];
}

interface PharmReceipt {
	id: number;
	isNew: boolean;
	prescriptionAt: string;
	totalAmount: number;
}

interface PharmGuidance {
	id: number;
	isNew: boolean;
	pharmacistName: string;
	hospital: string;
	prescriptionAt: string;
	prescriptionDays: number;
}

export type { FileMessageData, QRData, FileData };

export type MessageType = 'REQUEST' | 'RESPONSE' | 'REJECT';

interface BaseData<T extends MessageType> {
	type: T;
	groupId: string;
	parentMessageId?: number;
	rootMessageId?: number;
}

interface ExtraData {
	receipt: Receipt;
	sideEffect: SideEffect;
	payment: Payment;
	prescription: Prescription;
	promotion: Promotion;
	pharmSale: Promotion;
	disabled?: boolean;
	pharmReceipt: PharmReceipt;
	pharmGuidance: PharmGuidance;
}

type ExtraDataByCustomType<T extends keyof ExtraData> = Pick<ExtraData, T>;

type ReceiptMessageData = BaseData<'REQUEST'> & ExtraDataByCustomType<'receipt'>;
type SideEffectMessageData = BaseData<'REQUEST'> & ExtraDataByCustomType<'sideEffect'>;
type PaymentMessageData = BaseData<MessageType> & ExtraDataByCustomType<'payment' | 'disabled'>;
type PrescriptionMessageData =
	| (BaseData<'REQUEST'> & ExtraDataByCustomType<'prescription' | 'disabled'>)
	| BaseData<'RESPONSE' | 'REJECT'>;
type PromotionMessageData = BaseData<MessageType> & ExtraDataByCustomType<'promotion' | 'disabled'>;
type PharmSalesMessageData = BaseData<MessageType> & ExtraDataByCustomType<'pharmSale' | 'disabled'>;
type PharmReceiptMessageData = BaseData<'REQUEST'> & ExtraDataByCustomType<'pharmReceipt' | 'disabled'>;
type PharmGuidanceMessageData = BaseData<'REQUEST'> & ExtraDataByCustomType<'pharmGuidance' | 'disabled'>;

type MessageExtraData =
	| ReceiptMessageData
	| SideEffectMessageData
	| PaymentMessageData
	| PrescriptionMessageData
	| PromotionMessageData
	| PharmSalesMessageData
	| PharmReceiptMessageData
	| PharmGuidanceMessageData;

export type {
	Product,
	PaymentType,
	BasicMessageTypes,
	BaseData,
	ReceiptMessageData,
	SideEffectMessageData,
	PaymentMessageData,
	PrescriptionMessageData,
	PromotionMessageData,
	MessageExtraData,
	PharmSalesMessageData,
	PharmReceiptMessageData,
	PharmGuidanceMessageData,
};
