export const IconID = {
	NAV_DASHBOARD: 'nav-dashboard',
	NAV_MOBILE_PHARMACY: 'nav-mobile-pharmacy',
	NAV_MANUAL: 'nav-manual',
	NAV_MEMBER: 'nav-member',
	NAV_NOTICE: 'nav-notice',
	NAV_OPERATION: 'nav-operation',
	NAV_PAYMENT: 'nav-payment',
	NAV_PROMOTION: 'nav-promotion',
	NAV_DRUG: 'nav-drug',
	NAV_STATS: 'nav-stats',
	NAV_AS: 'nav-as',
	NAV_PRODUCT: 'nav-product',
	NAV_PAY_APP: 'nav-pay-app',
	BTN_ROUND_BACK: 'btn-round-back',
	BTN_ROUND_NEXT: 'btn-round-next',
	BTN_SEARCH: 'btn-search',
	ALARM: 'alarm',
	ALARM_PLUS: 'alarm-plus',
	CHEVRON: 'chevron',
	LOCK: 'lock',
	USER_LOGIN: 'user-login',
	USER: 'user',
	USER_PLUS: 'user-plus',
	USER_SETTING: 'user-setting',
	ALERT_CIRCLE: 'alert-circle',
	ALERT_CIRCLE_MEDIUM: 'alert-circle-medium',
	CIRCLE_DELETE: 'circle-delete',
	CHECKBOX_ROUNDED: 'checkbox-rounded',
	CHECKBOX_SQUARE_ON: 'checkbox-square-on',
	CHECKBOX_SQUARE_OFF: 'checkbox-square-off',
	NOTIFICATION_OFF: 'notification-off',
	PAGINATION_NEXT: 'pagination-next',
	PAGINATION_PREV: 'pagination-prev',
	PAGINATION_LAST: 'pagination-last',
	PAGINATION_FIRST: 'pagination-first',
	SORT: 'sort',
	SORT_DESC: 'sort-desc',
	SORT_ASC: 'sort-asc',
	FILE: 'file',
	BAN: 'ban',
	FILTER: 'filter',
	GROUP: 'group',
	GROUP_PLUS: 'group-plus',
	GROUP_MINUS: 'group-minus',
	GROUP_ADD: 'group-add',
	MESSAGE_PLUS: 'message-plus',
	MESSAGE_MINUS: 'message-minus',
	CHAT: 'chat',
	BTN_MORE: 'btn-more',
	BTN_PLUS: 'btn-plus',
	BTN_SEND: 'btn-send',
	BTN_CLOSE: 'btn-close',
	BTN_RADIO_ON: 'btn-radio-on',
	BTN_RADIO_OFF: 'btn-radio-off',
	CALENDER: 'calender',
	ROTATE_RIGHT: 'rotate-right',
	LINE_DATE_LEFT: 'line-date-left',
	LINE_DATE_RIGHT: 'line-date-right',
	BTN_PAYMENT: 'btn-payment',
	BTN_PHOTO: 'btn-photo',
	BTN_FILE: 'btn-file',
	BTN_TEMPLATE: 'btn-template',
	BTN_BOTTOM: 'btn-bottom',
	BTN_DELETE: 'btn-delete',
	BTN_DELETE_FAILED: 'btn-delete-failed',
	BTN_RESEND: 'btn-resend',
	MSG_PRESCRIPTION: 'msg-prescription',
	MSG_SIDE_EFFECT: 'msg-side-effect',
	MSG_RECEIPT: 'msg-receipt',
	MSG_PAYMENT: 'msg-payment',
	MSG_PROMOTION: 'msg-promotion',
	MSG_DOWNLOAD: 'msg-download',
	MSG_FILE: 'msg-file',
	MESSAGE: 'message',
	TRASH: 'trash',
	TRASH_FILLED: 'trash-filled',
	VIEWER_PRINTER: 'viewer-printer',
	VIEWER_DOWNLOAD: 'viewer-download',
	VIEWER_COPY: 'viewer-copy',
	VIEWER_CLOSE: 'viewer-close',
	VIEWER_ARROW: 'viewer-arrow',
	LINE_BOLD_FILE: 'line-bold-file',
	VIEWER_ZOOM_IN: 'viewer-zoom-in',
	VIEWER_ZOOM_OUT: 'viewer-zoom-out',
	VIEWER_PHOTO: 'viewer-photo',
	MEMBER_CIRCLE: 'member-circle',
	GROUP_CIRCLE: 'group-circle',
	BADGE: 'badge',
	COUPON: 'coupon',
	POINT: 'point',
	MARK_INFO: 'mark-info',
	MARK_QUESTION: 'mark-question',
	MARK_CHECKED: 'mark-checked',
	MARK_HASH: 'mark-hash',
	MARK_ALERT: 'mark-alert',
	CAMERA: 'camera',
	PHOTO_CANCEL: 'photo-cancel',
	EYE_ON: 'eye-on',
	EYE_OFF: 'eye-off',
	PENCIL: 'pencil',
	PIN_ON: 'pin-on',
	PIN_OFF: 'pin-off',
	BELL: 'bell',
	VACATION: 'vacation',
	EXPIRED_FILE: 'expired-file',
	ARROW_NARROW: 'arrow-narrow',
	ARROW_CIRCLE: 'arrow-circle',
	SAVE: 'save',
	DOWNWARDS_RIGHT: 'downwards-right',
	STATS_PROFIT: 'stats-profit',
	STATS_SALES: 'stats-sales',
	STATS_CARD: 'stats-card',
	STATS_PILL: 'stats-pill',
	STATS_USER: 'stats-user',
	FILLED_UP: 'filled-up',
	FILLED_DOWN: 'filled-down',
	UPDATE: 'update',
	SEARCH_DETAILS: 'search-details',
	DOWNLOAD_RIGHT: 'download-right',
	PILL_LINE: 'pill-line',
} as const;
export type Types = (typeof IconID)[keyof typeof IconID];
