import instance from '../../lib/axios/instance';

import type { ReceiptPK } from './types';

export default {
	getReceiptPDF: async <T extends ReceiptPK, R = Blob>({ storeId, id }: T): Promise<R> =>
		(await instance.get(`/pharms/${storeId}/receipts/${id}/pdf`, { responseType: 'blob' })).data,
	getReceiptHTML: async <T extends ReceiptPK, R = string>({ storeId, id }: T): Promise<R> =>
		(await instance.get(`/pharms/${storeId}/receipts/${id}/html`)).data,
};
