import React, { useCallback } from 'react';

import { toast } from 'react-toastify';

import { MessageHeader } from './MessageHeader';
import { buttonStyle, Container, Wrapper } from './styles';
import { Button } from '../../../../../components/Buttons';
import { Flex, VStack } from '../../../../../components/Common';
import { IconID, Label } from '../../../../../components/Display';
import context from '../../../../../context';
import { GUIDANCE_KEYS, useGetGuidanceHTML, useGetGuidancePDF } from '../../../../../services/guidances/queries';
import { formatDate } from '../../../../../utils/format';
import { onDownloadBlob } from '../../../../../utils/onDownloadBlob';
import { useChannelContext } from '../../../context/ChannelProvider';
import { getMessageData } from '../../../getMessageData';

import type { PharmGuidanceMessageData } from '../../../types';
import type { UserMessage } from '@sendbird/chat/message';

const PharmGuidanceMessage = ({ message }: { message: UserMessage }) => {
	const { disabledReason } = useChannelContext();
	const { userInfo } = context.user.useValue();
	const { mutate: pdfMutate } = useGetGuidancePDF();
	const { mutate: htmlMutate } = useGetGuidanceHTML();
	const messageData = getMessageData<PharmGuidanceMessageData>(message.data);

	if (messageData === null) return <></>;
	const { pharmGuidance } = messageData;
	const { pharmacistName, hospital, prescriptionAt, prescriptionDays, id } = pharmGuidance;

	const renderObjects = [
		{
			label: '조제약사',
			value: pharmacistName,
		},
		{
			label: '발행기관',
			value: hospital,
		},
		{
			label: '접수일시',
			value: formatDate(new Date(prescriptionAt), 'yyyy년 MM월 dd일 hh:mm'),
		},
		{
			label: '복용완료일',
			value: prescriptionDays + '일 이내',
		},
	];

	const handlePDF = useCallback(() => {
		pdfMutate(
			{ id, storeId: userInfo.storeId },
			{
				onSuccess: (data) => {
					onDownloadBlob(data, `복약안내서 ${prescriptionAt}.pdf`);
				},
				onError: (err) => {
					toast.error('다운로드를 실패했습니다.\n' + err.response?.data.message);
				},
			},
		);
	}, [id, userInfo]);

	const handleHTML = useCallback(() => {
		htmlMutate(
			{ id, storeId: userInfo.storeId },
			{
				onSuccess: (data) => {
					const newWindow = window.open('', 'popup', 'top=10, left=10, width=765, height=860');
					if (!newWindow) return null;

					newWindow.document.write(data);

					newWindow.document.close();
				},
				onError: (err) => {
					toast.error('상세보기를 실패했습니다.\n' + err.response?.data.message);
				},
			},
		);
	}, [id, userInfo]);

	return (
		<Container>
			<Wrapper>
				<MessageHeader
					iconType={IconID.PILL_LINE}
					title={'복약안내서'}
					subText={'고객님께 복약안내서를 발송했습니다.'}
				/>
				<VStack $padding="1.2rem 1.6rem 2.4rem" $gap="0.8rem" style={{ width: '100%' }}>
					{renderObjects.map(({ label, value }, idx) => (
						<Flex key={'object--' + String(idx)} $justify="between" $gap="0.8rem">
							<Label $fontStyle="body_3" $color="gray_700">
								{label}
							</Label>
							<Label $fontStyle="body_3" $color="gray_900">
								{value}
							</Label>
						</Flex>
					))}
				</VStack>
			</Wrapper>
			<Wrapper style={{ padding: '0 1.6rem 1.6rem' }}>
				<Flex $gap={'0.8rem'} $alignSelf={'stretch'}>
					<Button
						onClick={handlePDF}
						color={'TERTIARY'}
						shouldPrevent={true}
						mutationKey={[...GUIDANCE_KEYS.getGuidancePDF()]}
						disabled={disabledReason !== 'none'}
						{...buttonStyle}
					>
						다운로드
					</Button>
					<Button
						onClick={handleHTML}
						shouldPrevent={true}
						mutationKey={[...GUIDANCE_KEYS.getGuidanceHTML()]}
						color={'SECONDARY'}
						disabled={disabledReason !== 'none'}
						{...buttonStyle}
					>
						상세보기
					</Button>
				</Flex>
			</Wrapper>
		</Container>
	);
};

export default PharmGuidanceMessage;
