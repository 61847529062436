import React, { useCallback } from 'react';

import { toast } from 'react-toastify';

import { MessageHeader, type MessageHeaderProps } from './MessageHeader';
import { buttonStyle, Container, Divider, Wrapper } from './styles';
import { Button } from '../../../../../components/Buttons';
import { Flex, VStack } from '../../../../../components/Common';
import { IconID, Label } from '../../../../../components/Display';
import context from '../../../../../context';
import { RECEIPT_KEYS, useGetReceiptHTML, useGetReceiptPDF } from '../../../../../services/receipts/queries';
import { commaizeNumber, formatDate } from '../../../../../utils/format';
import { onDownloadBlob } from '../../../../../utils/onDownloadBlob';
import { useChannelContext } from '../../../context/ChannelProvider';
import { getMessageData } from '../../../getMessageData';

import type { PharmReceiptMessageData } from '../../../types';
import type { UserMessage } from '@sendbird/chat/message';

type HeaderType = 'NEW' | 'RESEND';

const HEADER_DATA: Record<HeaderType, MessageHeaderProps> = {
	NEW: {
		iconType: IconID.MSG_RECEIPT,
		title: '약품 영수증',
		subText: '고객님께 약품 영수증을 발송했습니다.',
	},
	RESEND: {
		iconType: IconID.MSG_RECEIPT,
		title: '약품 영수증 재발송',
		subText: '고객님께 약품 영수증을 다시 발송했습니다.',
	},
} as const;

const PharmReceiptMessage = ({ message }: { message: UserMessage }) => {
	const { disabledReason } = useChannelContext();
	const { userInfo } = context.user.useValue();
	const { mutate: pdfMutate } = useGetReceiptPDF();
	const { mutate: htmlMutate } = useGetReceiptHTML();
	const messageData = getMessageData<PharmReceiptMessageData>(message.data);

	if (messageData === null) return <></>;
	const { pharmReceipt } = messageData;
	const { prescriptionAt, totalAmount, id } = pharmReceipt;

	const handlePDF = useCallback(() => {
		pdfMutate(
			{ id, storeId: userInfo.storeId },
			{
				onSuccess: (data) => {
					onDownloadBlob(data, `약품 영수증 ${prescriptionAt}.pdf`);
				},
				onError: (err) => {
					toast.error('다운로드를 실패했습니다.\n' + err.response?.data.message);
				},
			},
		);
	}, [id, userInfo]);

	const handleHTML = useCallback(() => {
		htmlMutate(
			{ id, storeId: userInfo.storeId },
			{
				onSuccess: (data) => {
					const newWindow = window.open('', 'popup', 'top=10, left=10, width=765, height=860');
					if (!newWindow) return null;

					newWindow.document.write(data);

					newWindow.document.close();
				},
				onError: (err) => {
					toast.error('상세보기를 실패했습니다.\n' + err.response?.data.message);
				},
			},
		);
	}, [id, userInfo]);

	return (
		<Container>
			<Wrapper>
				<MessageHeader {...HEADER_DATA.NEW} />
				<Flex $padding="0.8rem 1.6rem 1.6rem" $justify="between" $gap="0.8rem" style={{ width: '100%' }}>
					<Label $fontStyle="body_3" $color="gray_700">
						조제일시
					</Label>
					<Label $fontStyle="body_3" $color="gray_900">
						{formatDate(new Date(prescriptionAt), 'yyyy년 MM월 dd일 hh:mm')}
					</Label>
				</Flex>
				<Divider />
				<Flex
					$gap={'1.2rem'}
					$direction={'column'}
					$alignSelf={'stretch'}
					$alignItems={'start'}
					$padding="1.2rem 1.6rem 1.6rem"
				>
					<VStack $gap="0.2rem" $width="100%">
						<Label $fontStyle={'body_3'} $fontWeight="bold">
							총 수납액
						</Label>
						<Label $fontStyle={'title_1'} $fontWeight={'bold'} $alignSelf="end">
							{commaizeNumber(totalAmount)}원
						</Label>
					</VStack>
				</Flex>
			</Wrapper>
			<Wrapper style={{ padding: '0 1.6rem 1.6rem' }}>
				<Flex $gap={'0.8rem'} $alignSelf={'stretch'}>
					<Button
						onClick={handlePDF}
						color={'TERTIARY'}
						shouldPrevent={true}
						mutationKey={[...RECEIPT_KEYS.getReceiptPDF()]}
						disabled={disabledReason !== 'none'}
						{...buttonStyle}
					>
						다운로드
					</Button>
					<Button
						onClick={handleHTML}
						shouldPrevent={true}
						mutationKey={[...RECEIPT_KEYS.getReceiptHTML()]}
						color={'SECONDARY'}
						disabled={disabledReason !== 'none'}
						{...buttonStyle}
					>
						상세보기
					</Button>
				</Flex>
			</Wrapper>
		</Container>
	);
};

export default PharmReceiptMessage;
