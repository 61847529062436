import { type UserMessage } from '@sendbird/chat/message';

import {
	AUTO_CUSTOM_TYPES,
	type AutoCustomType,
	FUNC_CUSTOM_TYPES,
	type FuncCustomType,
	type MessageTypes,
} from '../../types';
import { isAnnouncementMessage } from '../MessageContent/BasicMessage/utils';

import type { CoreMessageType } from '../../utils';

function isAutoMessage(value: string): value is AutoCustomType {
	return AUTO_CUSTOM_TYPES.includes(value as AutoCustomType);
}

function isFuncMessage(value: string): value is FuncCustomType {
	return FUNC_CUSTOM_TYPES.includes(value as FuncCustomType);
}

export const getMessageType = (message: CoreMessageType): MessageTypes => {
	let { customType } = message;

	if (isAssemblyMessage(message)) {
		return 'ASSEMBLY';
	} else if (customType.startsWith('GP_')) {
		customType = customType.replace('GP_', '');
		if (isAutoMessage(customType)) {
			return 'AUTO';
		}
		if (isFuncMessage(customType)) {
			return 'FUNCTIONAL';
		}
	}
	return 'BASIC';
};

export const isResendableMessage = (message: CoreMessageType, isByMe: boolean) => {
	if (!isByMe) return false;
	if (message.sendingStatus !== 'failed') return false;

	if (getMessageType(message) !== 'BASIC') return false;
	if (isAnnouncementMessage(message as UserMessage)) return false;

	return true;
};

export const isAssemblyMessage = (message: CoreMessageType): boolean => {
	if (!message.data) return false;
	const jsonData: object = JSON.parse(message?.data);

	return 'view' in jsonData;
};
