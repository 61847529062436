import { useMutation } from '@tanstack/react-query';

import { api } from '..';

import type { ReceiptPK } from './types';
import type { ErrorData } from '../types';
import type { AxiosError } from 'axios';

export const RECEIPT_KEYS = {
	all: ['receipt'] as const,
	getReceiptPDF: () => [...RECEIPT_KEYS.all, 'pdf'] as const,
	getReceiptHTML: () => [...RECEIPT_KEYS.all, 'html'] as const,
} as const;

export const useGetReceiptPDF = () =>
	useMutation<Blob, AxiosError<ErrorData>, ReceiptPK>({
		mutationKey: RECEIPT_KEYS.getReceiptPDF(),
		mutationFn: async (params) => await api.receipts.getReceiptPDF(params),
	});

export const useGetReceiptHTML = () =>
	useMutation<string, AxiosError<ErrorData>, ReceiptPK>({
		mutationKey: RECEIPT_KEYS.getReceiptPDF(),
		mutationFn: async (params) => await api.receipts.getReceiptHTML(params),
	});
