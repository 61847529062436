import React from 'react';

import { PaymentMessages } from './PaymentMessages';
import PharmGuidanceMessage from './PharmGuidanceMessage';
import PharmReceiptMessage from './PharmReceiptMessage';
import { PrescriptionMessages } from './PrescriptionMessages';
import { PromotionMessage } from './PromotionMessages';
import { ReceiptMessage } from './ReceiptMessage';
import { SideEffectMessage } from './SideEffectMessage';

import type { FuncCustomType } from '../../../types';
import type { UserMessage } from '@sendbird/chat/message';

type GPFuncCustomType = `GP_${FuncCustomType}`;

interface CustomMessageProps {
	message: UserMessage;
}
export const FunctionalMessage = ({ message }: CustomMessageProps) => {
	const messageConfigs: Record<GPFuncCustomType, React.ReactElement> = {
		GP_RECEIPT: <ReceiptMessage message={message} />,
		GP_SIDE_EFFECT: <SideEffectMessage message={message} />,
		GP_PAYMENT: <PaymentMessages message={message} />,
		GP_PRESCRIPTION: <PrescriptionMessages message={message} />,
		GP_PROMOTION: <PromotionMessage message={message} />,
		GP_PHARM_SALE: <PromotionMessage message={message} />,
		GP_PHARM_GUIDANCE: <PharmGuidanceMessage message={message} />,
		GP_PHARM_RECEIPT: <PharmReceiptMessage message={message} />,
	};

	return <>{messageConfigs[message.customType as GPFuncCustomType]}</>;
};
