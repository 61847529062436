import React, { useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading, VStack } from '../../../components/Common';
import { Description, type DescriptionProps, TextEditorViewer } from '../../../components/Display';
import { useGetNoticeDetail } from '../../../services/notice/queries';
import { type NoticeItem } from '../../../services/notice/types';

const NoticeDetail = () => {
	const { id } = useParams();

	const navigate = useNavigate();

	const { data, isLoading, isError } = useGetNoticeDetail({ id: Number(id) });

	useEffect(() => {
		if (!id || isError) {
			toast.error('유효하지 않은 페이지 입니다.');
			navigate(-1);
		}
	}, [id, isError]);

	const rows = useMemo(
		() =>
			[
				{ key: 'title', label: '제목' },
				{ key: 'publishedAt', label: '등록일' },
				{
					key: 'link',
					label: '링크',
					renderContent: (_, value) => (
						<a href={String(value)} target="_blank" rel="noreferrer">
							{value}
						</a>
					),
				},
			] as DescriptionProps<NoticeItem>['rows'],
		[],
	);

	return (
		<VStack $gap="4rem">
			{isLoading && <Loading />}
			<Description data={data} rows={rows} />
			<VStack $gap="1rem">
				{data && (
					<React.Fragment>
						<TextEditorViewer data={data.content} />
						{data.imageUrls.length > 0 && (
							<VStack>
								{data.imageUrls.map((imageUrl, index) => (
									<img
										key={"image--" + String(index)}
										src={imageUrl}
										alt="notice__image"
										style={{width: 'fit-content', maxWidth: '100%', margin: '0 auto'}}
									/>
								))}
							</VStack>
						)}
					</React.Fragment>
				)}
			</VStack>
		</VStack>
	);
};
export default NoticeDetail;
