import { asset } from './asset';
import { block } from './block';
import { chat } from './chat';
import { contracts } from './contracts';
import { faq } from './faq';
import { groups } from './groups';
import guidances from './guidances';
import { member } from './member';
import { notice } from './notice';
import notificationConfigs from './notification-configs';
import { payment } from './payment';
import { pharm } from './pharm';
import { products } from './products';
import { promotions } from './promotion';
import receipts from './receipts';
import { sales } from './sales';
import { statistics } from './statistics';
import { templates } from './templates';
import { user } from './users';
import instance from '../lib/axios/instance';

import type { AxiosRequestConfig, AxiosResponse } from 'axios';

export const Get = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
	await instance.get(url, config);

export const Post = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
	await instance.post(url, data, config);

export const Put = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
	await instance.put(url, data, config);

export const Delete = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
	await instance.delete(url, config);

export const Patch = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
	await instance.patch(url, data, config);

export const api = {
	guidances,
	receipts,
	notificationConfigs,
	user,
	pharm,
	member,
	groups,
	block,
	templates,
	payment,
	asset,
	chat,
	contracts,
	notice,
	promotions,
	faq,
	sales,
	products,
	statistics,
};
